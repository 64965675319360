/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useRef } from 'react';
import {
    string, func, shape, arrayOf, bool,
    array,
} from 'prop-types';
import { useQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';

// redux, hooks, helpers
import { GRAPHQL_ENV } from '../../../gql';
import FlashDealUI from './FlashDealUI';
import findSkuByPartNumbers from '../../../gql/queries/findSkuByPartNumbers';
import HeroSkeleton from '../GraphqlSkeletonComponents/HeroSkeleton';
import useClickStreamCustomEventsTracking from '../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import useSalesforceProductRecommendationQuery from '../../../helpers/Personalization/useSalesforceProductRecommendationQuery';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

const isProductAvailable = (product) => product?.availability?.availabilityIndicator === 'true';

const isProductPublished = (product) => product?.availability?.published || false; // returning bool in gql

const transformProducts = (products) => products.map((product) => { // restructuring salesforce data for AB test
    const {
        partNumber, name, image, seo, skuPriceRange,
    } = product;

    const prices = [
        ...(skuPriceRange.retail || []).map((price) => ({
            __typename: 'Price',
            currency: null,
            type: 'retail',
            value: price.value,
        })),
        ...(skuPriceRange.sale || []).map((price) => ({
            __typename: 'Price',
            currency: null,
            type: 'sale',
            value: price.value,
        })),
    ];

    return {
        __typename: 'ProductSku',
        brand: 'harryanddavid',
        brandId: '1019',
        id: partNumber,
        partNumber,
        availability: {
            __typename: 'ProductAvailability',
            availabilityIndicator: 'true',
            published: true,
        },
        name,
        productType: 'REGULAR_PRODUCT',
        prices,
        image: {
            __typename: 'ProductImage',
            path: image.path,
            name: image.name,
        },
        parentProduct: {
            __typename: 'ProductBase',
            seo: {
                __typename: 'Seo',
                url: seo.url,
            },
            partNumber,
        },
    };
});

const FlashDealContentContainer = ({
    content, handleCloseTo, brand, heading, tracking, isCheckoutRecommendation, nbcuMovie, recipients, skuData,
}) => {
    const isSalesforceEnabled = useSelector(getFeatureFlag('is-salesforce-minicart-recs-enabled'));
    const seedPartNumber = recipients?.[recipients?.length - 1]?.cartItems?.[0]?.product?.parentProductCode;
    const persistedPartNumber = useRef(seedPartNumber);
    // skuData of current selected add to cart product from PDP.
    const isSympathySku = skuData.some((sku) => sku.sympathyValue === '1' || sku.sympathyValue === '2');

    const { data: salesForceData, loading: salesForceLoading, error: salesForceError } = useSalesforceProductRecommendationQuery({
        queryName: 'MiniCartRecommendations',
        campaignFeature: 'Mini Cart Recs',
        partNumber: persistedPartNumber.current,
        featureFlag: isSalesforceEnabled,
        targeting: { pageType: 'product' },
        filterByZipCode: true,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
        isSympathySku,
    });

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: salesForceData?.productRecommendationsSFMulti?.[0],
        isFireImpression: true,
        page: { type: 'product' },
    });

    if (salesForceError) {
        mbpLogger.logError({
            function: 'FlashDealContentContainer.js',
            message: 'Error loading data from saleforce api',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: salesForceError,
        });
    }

    const getPartNumbers = [];
    content.forEach((getSkuData) => {
        getPartNumbers.push(`"${getSkuData?.sku}"`);
    });

    const partNumbers = `[${getPartNumbers.join(',')}]`;

    const FLASH_DEAL_QUERY = findSkuByPartNumbers(
        brand.domain,
        partNumbers,
        `brand
        brandId
        id
        partNumber
        availability{
            availabilityIndicator
            published
        }
        name
        productType
        prices {
            currency
            type
            value
        }
        image {
            name
            path
        }
        parentProduct {
            seo {
                url
            }
            partNumber
        }`,
    );
    const { loading, error, data } = useQuery(FLASH_DEAL_QUERY);
    if (loading || salesForceLoading) {
        return  <HeroSkeleton height="200px" width="100%" />;
    }
    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: FLASH_DEAL_QUERY,
            jsError: error,
            component: 'FlashDealContentContainer.js',
            message: `Failed to fetch content data for: content type on brand ${brand}`,
            env: GRAPHQL_ENV,
        });
        handleCloseTo(false);
    }
    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: FLASH_DEAL_QUERY,
            component: 'FlashDealContentContainer.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        handleCloseTo(false);
        return null;
    }
    let getFlashData = data?.findSkuByPartNumbers?.filter((sku) => (isProductAvailable(sku) && isProductPublished(sku))) || [];
    let isSalesforceRecsRendered = false;

    const userGroup = salesForceData?.productRecommendationsSFMulti?.[0]?.campaign?.campaignResponses?.[0]?.payload?.userGroup;
    if (salesForceData?.productRecommendationsSFMulti?.[0]?.products?.length > 0 && userGroup === 'Test') {
        getFlashData = transformProducts(salesForceData?.productRecommendationsSFMulti?.[0]?.products);
        isSalesforceRecsRendered = getFlashData.length > 0;
    }

    return (
        <FlashDealUI isCheckoutRecommendation={isCheckoutRecommendation} tracking={tracking} productClickCallback={trackClickThrough} content={getFlashData} heading={heading} handleCloseTo={handleCloseTo} nbcuMovieData={nbcuMovie} isSalesforceRecsRendered={isSalesforceRecsRendered} />
    );
};

FlashDealContentContainer.propTypes = {
    content: arrayOf(shape({
        sku: string.isRequired,
    })),
    brand: shape({
        domain: string,
    }).isRequired,
    handleCloseTo: func,
    heading: string.isRequired,
    tracking: shape({
        eventCategory: '',
        eventAction: '',
    }),
    isCheckoutRecommendation: bool,
    nbcuMovie: arrayOf(arrayOf(shape({
        sku: string.isRequired,
        nbcu_see_titles: string.isRequired,
        info_on_hover: string.isRequired,
    }))),
    recipients: array,
    skuData: array,
};
FlashDealContentContainer.defaultProps = {
    content: [],
    handleCloseTo: () => {},
    tracking: {},
    isCheckoutRecommendation: false,
    nbcuMovie: [],
    recipients: [],
    skuData: [],
};

export default (FlashDealContentContainer);
