/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, {  useEffect, useContext } from 'react';
import { connect,  useSelector } from 'react-redux';
import {
    object, bool, func, string, number, shape, array,
} from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
    getCartCount,
    getOrderId,
    getOrderContainsSubscriptionItem,
    getPmallSummaryInfo,
} from '../../../../../../../../state/ducks/Common/Common-Selectors';
import { getIsAuthenticatedStatus, getContactId } from '../../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import {  resetShopTheSiteStorage } from '../../../../../../../pages/Account/utils/shopTheSite';

import { triggerInterstitialLogin } from '../../../../../../../../state/ducks/Member/ducks/Auth/Auth-Actions';
import {
    getSSRDeviceType,
} from '../../../../../../../../state/ducks/App/App-Selectors';
import { setMiniCartUpsellState, setMiniCartModalState } from '../../../../../../../../state/ducks/App/App-Actions';

import { trackEvent as track } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getLastAddedOrderItemDetails } from '../../../../../../../../state/ducks/AddToCart/AddToCart-Selectors';
import { removePmallItem } from '../../../../../../../../state/ducks/Common/ducks/PmallPersonalization/PmallPersonalization-Actions';
import { getFeatureFlag } from '../../../../../../../../state/ducks/Checkout/ducks/Global/Global-Selectors';

import { CrossMerch } from '../../../../../../GraphqlComponents/CrossMerchContainer/CrossMerchContainer';
import SlideOutMiniCart from '../SlideOutMiniCart/SlideOutMiniCart';
import {  getFeatureFlags } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useSalesforcePersonalizedContentQuery from '../../../../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import { getBrand } from '../../../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

const MiniCart = ({
    minicartModalOpen,
    handleMiniCartModalClick,
    history,
    cartCount,
    orderId,
    actionTriggerInterstitialLogin,
    productAdded,
    contactId,
    isAuthenticated,
    disableMiniCartUpsell,
    trackEvent,
    callRemovePmallItem,
    actionSetMiniCartModalState,
    isInterstitialLoginEnabled,
    isSubscriptionInterstitialLoginEnabled,
    device,
    orderContainsSubscriptionItem,
    pmallSummaryInfo,
    brand,
    skuData,
}) => {
    if (!productAdded) return null;
    const cartOrderProduct = productAdded || {};
    const { featureFlags = {} } = useContext(CrossMerch);
    let setFeatureFlags = featureFlags;
    const { data: atcLoginData } = useSalesforcePersonalizedContentQuery({
        queryName: 'atcLoginRedirect',
        interactionName: `${brand?.domain} - Get Campaign - ATC login redirect`,
        featureFlag: featureFlags['isInterstitialLoginEnabled'],
    });

    const atcLoginRedirect = atcLoginData?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup;
    const atcLoginEnabled = atcLoginRedirect?.toLowerCase?.() !== 'test';

    if (Object.keys(setFeatureFlags)?.length < 1 || typeof setFeatureFlags === 'undefined') {
        setFeatureFlags = useSelector(getFeatureFlags);
    }

    if (pmallSummaryInfo?.customizations?.refnum && setFeatureFlags['is-pmall-enabled'] && (cartOrderProduct?.brandCode === '1030' || cartOrderProduct?.brandCode === '1033' || cartOrderProduct?.personalization?.personalizationType === 'EXTERNAL') && history.location?.pathname !== '/account/gift-list') {
        callRemovePmallItem();
    }

    useEffect(() => {
        if (minicartModalOpen) {
            trackEvent({
                eventCategory: 'Test Impression',
                eventAction: 'Mini Cart',
                eventLabel: `Variant - ${device}`,
            });
        }
    }, [minicartModalOpen]);

    // Tell the app Mini Cart Dialog's current state
    useEffect(() => {
        actionSetMiniCartModalState(minicartModalOpen);
    }, [minicartModalOpen]);

    const handelOncloseState = () => {
        handleMiniCartModalClick(false);
        // Clear Shop The Site data
        resetShopTheSiteStorage(contactId);
        disableMiniCartUpsell(false);
    };

    const handleCheckout = (redirectKey) => {
        trackEvent({
            eventCategory: 'Checkout',
            eventAction: 'Checkout Click',
            eventLabel: cartOrderProduct.product?.parentProductName,
        });
        const eventParam = {
            eventCategory: 'Experiment',
            eventAction: 'ATC Login Redirect',
            eventLabel: atcLoginEnabled ? 'Control' : 'Variant',
            feature_detail: atcLoginEnabled ? 'Control' : 'Variant',
            feature_element: 'ATC Login Redirect',
            feature_category: 'Experiment',
        };
        if (cartCount < 1) return null;

        if (isInterstitialLoginEnabled && orderContainsSubscriptionItem.length <= 0) {
            trackEvent(eventParam);
        }
        switch (redirectKey) {
            case 'cart':
                history.push(`/checkout/cart/${orderId}`);
                break;
            case 'checkout':
                // TODO ::: commenting out for now need to verifiy if actionValidateShopCart is required
                // actionValidateShopCart({ orderId, history });
                if (isSubscriptionInterstitialLoginEnabled && orderContainsSubscriptionItem.length > 0 && !isAuthenticated) {
                    actionTriggerInterstitialLogin(history, true);
                } else if (isInterstitialLoginEnabled && atcLoginEnabled) {
                    actionTriggerInterstitialLogin(history);
                } else {
                    history.push(`/checkout/shipping/${orderId}`);
                }
                break;
            default:
                return null;
        }
        return null;
    };

    return (
        minicartModalOpen
            ? (
                <SlideOutMiniCart
                    openDrawer={minicartModalOpen}
                    closeDrawer={handelOncloseState}
                    handleCheckout={handleCheckout}
                    skuData={skuData}
                />
            ) : <></>
    );
};

MiniCart.propTypes = {
    minicartModalOpen: bool.isRequired,
    handleMiniCartModalClick: func.isRequired,
    history: object.isRequired,
    cartCount: number.isRequired,
    orderId: string.isRequired,
    productAdded: shape({
        product: shape({
            parentSkuName: string.isRequired,
            parentSkuCode: string.isRequired,
            skuOfferPrice: number.isRequired,
            skuImageName: string.isRequired,
            imagePath: string.isRequired,
        }),
    }).isRequired,
    contactId: string,
    actionTriggerInterstitialLogin: func.isRequired,
    isAuthenticated: bool,
    disableMiniCartUpsell: func.isRequired,
    trackEvent: func,
    callRemovePmallItem: func.isRequired,
    actionSetMiniCartModalState: func.isRequired,
    isInterstitialLoginEnabled: bool,
    orderContainsSubscriptionItem: array,
    isSubscriptionInterstitialLoginEnabled: bool,
    device: string,
    pmallSummaryInfo: object,
    brand: object.isRequired,
    skuData: array,
};

MiniCart.defaultProps = {
    contactId: '',
    isAuthenticated: false,
    trackEvent: () => {},
    isInterstitialLoginEnabled: false,
    orderContainsSubscriptionItem: [],
    isSubscriptionInterstitialLoginEnabled: false,
    device: '',
    pmallSummaryInfo: {},
    skuData: [],
};

const mapStateToProps = (state) => ({
    contactId: getContactId(state),
    cartCount: getCartCount(state),
    orderId: getOrderId(state),
    productAdded: getLastAddedOrderItemDetails(state),
    isAuthenticated: getIsAuthenticatedStatus(state),
    isInterstitialLoginEnabled: getFeatureFlag('is-interstitial-login-enabled')(state),
    isSubscriptionInterstitialLoginEnabled: getFeatureFlag('is-subscription-interstitial-login-enabled')(state),
    orderContainsSubscriptionItem: getOrderContainsSubscriptionItem(state),
    device: getSSRDeviceType(state),
    pmallSummaryInfo: getPmallSummaryInfo(state),
    brand: getBrand(state),

});

const mapDispatchToProps = (dispatch) => ({
    actionTriggerInterstitialLogin: bindActionCreators(triggerInterstitialLogin, dispatch),
    disableMiniCartUpsell: bindActionCreators(setMiniCartUpsellState, dispatch),
    trackEvent: bindActionCreators(track, dispatch),
    callRemovePmallItem: bindActionCreators(removePmallItem, dispatch),
    actionSetMiniCartModalState: bindActionCreators(setMiniCartModalState, dispatch),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
);

export default enhance(MiniCart);
